import _definePage_default_0 from '/var/www/vijaya/src/pages/app/[...error].vue?definePage&vue'
import { _mergeRouteRecord } from 'unplugin-vue-router/runtime'
import _definePage_default_2 from '/var/www/vijaya/src/pages/app/auth/role.vue?definePage&vue'
import _definePage_default_3 from '/var/www/vijaya/src/pages/app/capa-management.vue?definePage&vue'
import _definePage_default_4 from '/var/www/vijaya/src/pages/app/customer-communication.vue?definePage&vue'
import _definePage_default_5 from '/var/www/vijaya/src/pages/app/dashboard/index.vue?definePage&vue'
import _definePage_default_6 from '/var/www/vijaya/src/pages/app/dashboard/complaint.vue?definePage&vue'
import _definePage_default_7 from '/var/www/vijaya/src/pages/app/dashboard/department/index.vue?definePage&vue'
import _definePage_default_8 from '/var/www/vijaya/src/pages/app/dashboard/feedback.vue?definePage&vue'
import _definePage_default_9 from '/var/www/vijaya/src/pages/app/dashboard/rca.vue?definePage&vue'
import _definePage_default_10 from '/var/www/vijaya/src/pages/app/dashboard/ticket.vue?definePage&vue'
import _definePage_default_11 from '/var/www/vijaya/src/pages/app/escalation/index.vue?definePage&vue'
import _definePage_default_12 from '/var/www/vijaya/src/pages/app/escalation/create-update.vue?definePage&vue'
import _definePage_default_13 from '/var/www/vijaya/src/pages/app/feedback-entry/index.vue?definePage&vue'
import _definePage_default_14 from '/var/www/vijaya/src/pages/app/feedback-entry/assorted.vue?definePage&vue'
import _definePage_default_15 from '/var/www/vijaya/src/pages/app/feedback-entry/collection/index.vue?definePage&vue'
import _definePage_default_16 from '/var/www/vijaya/src/pages/app/feedback-history.vue?definePage&vue'
import _definePage_default_17 from '/var/www/vijaya/src/pages/app/feedback-panel.vue?definePage&vue'
import _definePage_default_18 from '/var/www/vijaya/src/pages/app/login.vue?definePage&vue'
import _definePage_default_19 from '/var/www/vijaya/src/pages/app/master/category.vue?definePage&vue'
import _definePage_default_20 from '/var/www/vijaya/src/pages/app/master/config.vue?definePage&vue'
import _definePage_default_21 from '/var/www/vijaya/src/pages/app/master/department.vue?definePage&vue'
import _definePage_default_22 from '/var/www/vijaya/src/pages/app/master/form-type.vue?definePage&vue'
import _definePage_default_23 from '/var/www/vijaya/src/pages/app/master/location.vue?definePage&vue'
import _definePage_default_24 from '/var/www/vijaya/src/pages/app/master/location-type.vue?definePage&vue'
import _definePage_default_25 from '/var/www/vijaya/src/pages/app/master/source.vue?definePage&vue'
import _definePage_default_26 from '/var/www/vijaya/src/pages/app/master/tag.vue?definePage&vue'
import _definePage_default_27 from '/var/www/vijaya/src/pages/app/master/team.vue?definePage&vue'
import _definePage_default_28 from '/var/www/vijaya/src/pages/app/master/unit.vue?definePage&vue'
import _definePage_default_29 from '/var/www/vijaya/src/pages/app/not-authorized.vue?definePage&vue'
import _definePage_default_30 from '/var/www/vijaya/src/pages/app/questionnaire/index.vue?definePage&vue'
import _definePage_default_31 from '/var/www/vijaya/src/pages/app/questionnaire/group.vue?definePage&vue'
import _definePage_default_32 from '/var/www/vijaya/src/pages/app/questionnaire/question.vue?definePage&vue'
import _definePage_default_33 from '/var/www/vijaya/src/pages/app/second-page.vue?definePage&vue'
import _definePage_default_34 from '/var/www/vijaya/src/pages/app/template/email.vue?definePage&vue'
import _definePage_default_35 from '/var/www/vijaya/src/pages/app/template/sms.vue?definePage&vue'
import _definePage_default_36 from '/var/www/vijaya/src/pages/app/template/whatsapp.vue?definePage&vue'
import _definePage_default_37 from '/var/www/vijaya/src/pages/app/ticket-management/index.vue?definePage&vue'
import _definePage_default_38 from '/var/www/vijaya/src/pages/app/ticket-management/board.vue?definePage&vue'
import _definePage_default_39 from '/var/www/vijaya/src/pages/app/ticket-management/compliment.vue?definePage&vue'
import _definePage_default_40 from '/var/www/vijaya/src/pages/app/users/index.vue?definePage&vue'
import _definePage_default_41 from '/var/www/vijaya/src/pages/app/users/user.vue?definePage&vue'
import _definePage_default_42 from '/var/www/vijaya/src/pages/app/utility/qrcode.vue?definePage&vue'
import _definePage_default_43 from '/var/www/vijaya/src/pages/feedback-collect/index.vue?definePage&vue'

export const routes = [
  {
    path: '/app',
    /* internal name: 'app' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: ':error(.*)',
        name: 'app$error',
        component: () => import('/var/www/vijaya/src/pages/app/[...error].vue'),
        /* no children */
      },
  _definePage_default_0
  ),
      {
        path: 'auth',
        /* internal name: 'app-auth' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'role',
            name: 'app-auth-role',
            component: () => import('/var/www/vijaya/src/pages/app/auth/role.vue'),
            /* no children */
          },
  _definePage_default_2
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'capa-management',
        name: 'app-capa-management',
        component: () => import('/var/www/vijaya/src/pages/app/capa-management.vue'),
        /* no children */
      },
  _definePage_default_3
  ),
  _mergeRouteRecord(
      {
        path: 'customer-communication',
        name: 'app-customer-communication',
        component: () => import('/var/www/vijaya/src/pages/app/customer-communication.vue'),
        /* no children */
      },
  _definePage_default_4
  ),
      {
        path: 'dashboard',
        /* internal name: 'app-dashboard' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'app-dashboard',
            component: () => import('/var/www/vijaya/src/pages/app/dashboard/index.vue'),
            /* no children */
          },
  _definePage_default_5
  ),
  _mergeRouteRecord(
          {
            path: 'complaint',
            name: 'app-dashboard-complaint',
            component: () => import('/var/www/vijaya/src/pages/app/dashboard/complaint.vue'),
            /* no children */
          },
  _definePage_default_6
  ),
          {
            path: 'department',
            /* internal name: 'app-dashboard-department' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-dashboard-department',
                component: () => import('/var/www/vijaya/src/pages/app/dashboard/department/index.vue'),
                /* no children */
              },
  _definePage_default_7
  )
            ],
          },
  _mergeRouteRecord(
          {
            path: 'feedback',
            name: 'app-dashboard-feedback',
            component: () => import('/var/www/vijaya/src/pages/app/dashboard/feedback.vue'),
            /* no children */
          },
  _definePage_default_8
  ),
  _mergeRouteRecord(
          {
            path: 'rca',
            name: 'app-dashboard-rca',
            component: () => import('/var/www/vijaya/src/pages/app/dashboard/rca.vue'),
            /* no children */
          },
  _definePage_default_9
  ),
  _mergeRouteRecord(
          {
            path: 'ticket',
            name: 'app-dashboard-ticket',
            component: () => import('/var/www/vijaya/src/pages/app/dashboard/ticket.vue'),
            /* no children */
          },
  _definePage_default_10
  )
        ],
      },
      {
        path: 'escalation',
        /* internal name: 'app-escalation' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'app-escalation',
            component: () => import('/var/www/vijaya/src/pages/app/escalation/index.vue'),
            /* no children */
          },
  _definePage_default_11
  ),
  _mergeRouteRecord(
          {
            path: 'create-update',
            name: 'app-escalation-create-update',
            component: () => import('/var/www/vijaya/src/pages/app/escalation/create-update.vue'),
            /* no children */
          },
  _definePage_default_12
  )
        ],
      },
      {
        path: 'feedback-entry',
        /* internal name: 'app-feedback-entry' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'app-feedback-entry',
            component: () => import('/var/www/vijaya/src/pages/app/feedback-entry/index.vue'),
            /* no children */
          },
  _definePage_default_13
  ),
  _mergeRouteRecord(
          {
            path: 'assorted',
            name: 'app-feedback-entry-assorted',
            component: () => import('/var/www/vijaya/src/pages/app/feedback-entry/assorted.vue'),
            /* no children */
          },
  _definePage_default_14
  ),
          {
            path: 'collection',
            /* internal name: 'app-feedback-entry-collection' */
            /* no component */
            children: [
  _mergeRouteRecord(
              {
                path: '',
                name: 'app-feedback-entry-collection',
                component: () => import('/var/www/vijaya/src/pages/app/feedback-entry/collection/index.vue'),
                /* no children */
              },
  _definePage_default_15
  )
            ],
          }
        ],
      },
  _mergeRouteRecord(
      {
        path: 'feedback-history',
        name: 'app-feedback-history',
        component: () => import('/var/www/vijaya/src/pages/app/feedback-history.vue'),
        /* no children */
      },
  _definePage_default_16
  ),
  _mergeRouteRecord(
      {
        path: 'feedback-panel',
        name: 'app-feedback-panel',
        component: () => import('/var/www/vijaya/src/pages/app/feedback-panel.vue'),
        /* no children */
      },
  _definePage_default_17
  ),
  _mergeRouteRecord(
      {
        path: 'login',
        name: 'app-login',
        component: () => import('/var/www/vijaya/src/pages/app/login.vue'),
        /* no children */
      },
  _definePage_default_18
  ),
      {
        path: 'master',
        /* internal name: 'app-master' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'category',
            name: 'app-master-category',
            component: () => import('/var/www/vijaya/src/pages/app/master/category.vue'),
            /* no children */
          },
  _definePage_default_19
  ),
  _mergeRouteRecord(
          {
            path: 'config',
            name: 'app-master-config',
            component: () => import('/var/www/vijaya/src/pages/app/master/config.vue'),
            /* no children */
          },
  _definePage_default_20
  ),
  _mergeRouteRecord(
          {
            path: 'department',
            name: 'app-master-department',
            component: () => import('/var/www/vijaya/src/pages/app/master/department.vue'),
            /* no children */
          },
  _definePage_default_21
  ),
  _mergeRouteRecord(
          {
            path: 'form-type',
            name: 'app-master-form-type',
            component: () => import('/var/www/vijaya/src/pages/app/master/form-type.vue'),
            /* no children */
          },
  _definePage_default_22
  ),
  _mergeRouteRecord(
          {
            path: 'location',
            name: 'app-master-location',
            component: () => import('/var/www/vijaya/src/pages/app/master/location.vue'),
            /* no children */
          },
  _definePage_default_23
  ),
  _mergeRouteRecord(
          {
            path: 'location-type',
            name: 'app-master-location-type',
            component: () => import('/var/www/vijaya/src/pages/app/master/location-type.vue'),
            /* no children */
          },
  _definePage_default_24
  ),
  _mergeRouteRecord(
          {
            path: 'source',
            name: 'app-master-source',
            component: () => import('/var/www/vijaya/src/pages/app/master/source.vue'),
            /* no children */
          },
  _definePage_default_25
  ),
  _mergeRouteRecord(
          {
            path: 'tag',
            name: 'app-master-tag',
            component: () => import('/var/www/vijaya/src/pages/app/master/tag.vue'),
            /* no children */
          },
  _definePage_default_26
  ),
  _mergeRouteRecord(
          {
            path: 'team',
            name: 'app-master-team',
            component: () => import('/var/www/vijaya/src/pages/app/master/team.vue'),
            /* no children */
          },
  _definePage_default_27
  ),
  _mergeRouteRecord(
          {
            path: 'unit',
            name: 'app-master-unit',
            component: () => import('/var/www/vijaya/src/pages/app/master/unit.vue'),
            /* no children */
          },
  _definePage_default_28
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'not-authorized',
        name: 'app-not-authorized',
        component: () => import('/var/www/vijaya/src/pages/app/not-authorized.vue'),
        /* no children */
      },
  _definePage_default_29
  ),
      {
        path: 'questionnaire',
        /* internal name: 'app-questionnaire' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'app-questionnaire',
            component: () => import('/var/www/vijaya/src/pages/app/questionnaire/index.vue'),
            /* no children */
          },
  _definePage_default_30
  ),
  _mergeRouteRecord(
          {
            path: 'group',
            name: 'app-questionnaire-group',
            component: () => import('/var/www/vijaya/src/pages/app/questionnaire/group.vue'),
            /* no children */
          },
  _definePage_default_31
  ),
  _mergeRouteRecord(
          {
            path: 'question',
            name: 'app-questionnaire-question',
            component: () => import('/var/www/vijaya/src/pages/app/questionnaire/question.vue'),
            /* no children */
          },
  _definePage_default_32
  )
        ],
      },
  _mergeRouteRecord(
      {
        path: 'second-page',
        name: 'app-second-page',
        component: () => import('/var/www/vijaya/src/pages/app/second-page.vue'),
        /* no children */
      },
  _definePage_default_33
  ),
      {
        path: 'template',
        /* internal name: 'app-template' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'email',
            name: 'app-template-email',
            component: () => import('/var/www/vijaya/src/pages/app/template/email.vue'),
            /* no children */
          },
  _definePage_default_34
  ),
  _mergeRouteRecord(
          {
            path: 'sms',
            name: 'app-template-sms',
            component: () => import('/var/www/vijaya/src/pages/app/template/sms.vue'),
            /* no children */
          },
  _definePage_default_35
  ),
  _mergeRouteRecord(
          {
            path: 'whatsapp',
            name: 'app-template-whatsapp',
            component: () => import('/var/www/vijaya/src/pages/app/template/whatsapp.vue'),
            /* no children */
          },
  _definePage_default_36
  )
        ],
      },
      {
        path: 'ticket-management',
        /* internal name: 'app-ticket-management' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'app-ticket-management',
            component: () => import('/var/www/vijaya/src/pages/app/ticket-management/index.vue'),
            /* no children */
          },
  _definePage_default_37
  ),
  _mergeRouteRecord(
          {
            path: 'board',
            name: 'app-ticket-management-board',
            component: () => import('/var/www/vijaya/src/pages/app/ticket-management/board.vue'),
            /* no children */
          },
  _definePage_default_38
  ),
  _mergeRouteRecord(
          {
            path: 'compliment',
            name: 'app-ticket-management-compliment',
            component: () => import('/var/www/vijaya/src/pages/app/ticket-management/compliment.vue'),
            /* no children */
          },
  _definePage_default_39
  )
        ],
      },
      {
        path: 'users',
        /* internal name: 'app-users' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: '',
            name: 'app-users',
            component: () => import('/var/www/vijaya/src/pages/app/users/index.vue'),
            /* no children */
          },
  _definePage_default_40
  ),
  _mergeRouteRecord(
          {
            path: 'user',
            name: 'app-users-user',
            component: () => import('/var/www/vijaya/src/pages/app/users/user.vue'),
            /* no children */
          },
  _definePage_default_41
  )
        ],
      },
      {
        path: 'utility',
        /* internal name: 'app-utility' */
        /* no component */
        children: [
  _mergeRouteRecord(
          {
            path: 'qrcode',
            name: 'app-utility-qrcode',
            component: () => import('/var/www/vijaya/src/pages/app/utility/qrcode.vue'),
            /* no children */
          },
  _definePage_default_42
  )
        ],
      }
    ],
  },
  {
    path: '/feedback-collect',
    /* internal name: 'feedback-collect' */
    /* no component */
    children: [
  _mergeRouteRecord(
      {
        path: '',
        name: 'feedback-collect',
        component: () => import('/var/www/vijaya/src/pages/feedback-collect/index.vue'),
        /* no children */
      },
  _definePage_default_43
  )
    ],
  }
]
